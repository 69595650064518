.raportStatic,
.raportStatic .search,
.raportStatic .search form,
.raportStatic .search form .leftCol,
.raportStatic .search form .rightCol {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
}
.raportStatic {
    width: 100%;
    height: auto;
    min-height: 100vh;
    align-content: flex-start;
}
.raportStatic h1 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.8vw;
    color: #6a6a6a;
    font-weight: 300;
}
.raportStatic h1 span {
    font-weight: 800;
    color: #252525;
}
.raportStatic .search {
    width: 100%;
    margin: 1vh 0;
}
.raportStatic .search form .leftCol {
    width: 25%;
    height: 100%;
}
.raportStatic .search form .rightCol {
    width: 70%;
    height: 100%;
}
.raportStatic .search form label {
    width: 100%;
    margin: 1vh 0;
}
.raportStatic .search form label p {
    margin: 0;
    padding: .5vh 0;
    font-size: 1vw;
    color: gray;
}
.raportStatic .search form label .select__control {
    font-size: 1.2vw;
    background-color: #f1f1f1;
    border: 0;
    border-bottom: 1px solid hsl(0, 0%, 80%);
    border-radius: 0px!important;
}
.raportStatic .search form input[type="datetime-local"] {
    width: 100%;
    font-family: Montserrat;
    border: 0;
    font-size: 1.2vw;
    border-bottom: 1px solid hsl(0, 0%, 80%);
    min-height: 38px;
    border-radius: 0px;
    transition: all 100ms;
    outline: 0!important;
    position: relative;
    display: flex;
    padding: 0px 10px;
    background-color: #f1f1f1;
}
@media only screen and (max-width: 767px) {
    .raportStatic h1 {
        font-size: 5vw;
    }
    .raportStatic .search {
        margin: 3vh 0;
    }
    .raportStatic .search form .leftCol {
        width: 100%;
        height: 50%;
    }
    .raportStatic .search form .rightCol {
        width: 100%;
        height: 50%;
    }
    .raportStatic .search form label {
        width: 49%;
        margin: 1vh 0;
        display: block;
    }
    .raportStatic .search form label p {
        padding: 1vh 0;
        font-size: 2.5vw;
    }
    .raportStatic .search form label .select__control {
        font-size: 3.2vw;
    }
    .raportStatic .search form input[type="datetime-local"] {
        width: 100%!important;
        font-size: 3.2vw;
        padding: 0px 0px;
        color: #000!important;
        display: block;
    }
}