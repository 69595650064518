.chartContainer,
.chartContainer .products {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    flex-direction: row;
}
.chartContainer {
    width: 100%;
    height: 100%;
    align-items: stretch;
    align-content: space-between;
    transition: .3s ease-in-out;
    background: #ffffff70;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);
}
.chartContainer:hover {
    box-shadow: 2px 2px 12px 0px rgb(190, 190, 190);
}
.chartContainer .theChart {
    box-sizing: border-box;
    display: block;
    height: 85%!important;
    width: 100%;
    padding: 0!important;
}
.chartContainer canvas {
    width: 100%!important;
    height: 100%!important;
    box-sizing: border-box;
    position: relative;
    display: block;
}
.chartContainer .products {
    width: 100%;
    height: 15%!important;
    border-radius: 0;
    margin: 0!important;
    padding: 0!important;
    justify-content: space-between;
}
.chartContainer .products * {
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.chartContainer .products div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 30%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
}
.chartContainer .products div div {
    height: 100%;
    width: 50%;
    box-sizing: border-box;
}
.chartContainer .products div h6 {
    font-size: 1vw;
    color: #a7a7a7;
    font-weight: 300;
    width: auto;
    border-bottom: 1px solid #a7a7a7;
}
.chartContainer .products div h2 {
    font-size: 1.7vw;
    color: rgb(27, 27, 27);
    font-weight: 600;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 50%;
    width: 100%;
}
.chartContainer .products div h5 {
    font-size: 1vw;
    color: #6e6e6e;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 50%;
}
.chartContainer .products div h5 span {
    font-size: .8vw;
    color: #c3c3c3;
}
@media only screen and (max-width: 767px) {
    .chartContainer .theChart {
        height: 80%!important;
        width: 100%;
        padding: 0!important;
    }
    .chartContainer canvas {
        height: 100%!important;
    }
    .chartContainer .products {
        height: 20%!important;
    }
    .chartContainer .products div h6 {
        font-size: 3vw;
    }
    .chartContainer .products div h2 {
        font-size: 4.2vw;
        width: 100%;
        display: block;
    }
    .chartContainer .products div div {
        height: 50%;
        width: 100%;
    }
    .chartContainer .products div h5 {
        font-size: 3vw;
        width: 50%;
        height: 100%;
    }
    .chartContainer .products div h5 span {
        font-size: 1.7vw;
    }
}