.previous-day-results {
     margin: 5vh 0;
}
.previous-day-results .inputs,
.previous-day-results .inputs .input-box,
.previous-day-results-products {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
}
.previous-day-results .inputs h1 {
    width: 100%;
    font-size: 1.8vw;
}
.previous-day-results .inputs .input-box {
    justify-content: space-between;
    width: 100%;
}
.previous-day-results .inputs .input-box label {
    width: 20%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}
.previous-day-results .inputs .input-box label p {
    width: 100%;
    font-size: 1vw;
    color: rgb(154, 154, 154);
    font-weight: 300;
}
.previous-day-results .inputs .input-box label select,
.previous-day-results .inputs .input-box label input {
    width: 100%;
    font-family: Montserrat;
    display: block;
    padding: 10px;
    border: 0;
    border: 0 0 1px 0 solid gray;
    border-radius: 0;
    background-color: #fff;
    color: #000;
}
.previous-day-results-products {
    width: 100%;
    margin: 3vh 0;
    justify-content: space-between;
}
.previous-day-results-products .products {
    width: 12%;
    max-width: 12%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 8px 0px 37px -19px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 0px 37px -19px rgba(66, 68, 90, 1);
    box-shadow: 8px 0px 37px -19px rgba(66, 68, 90, 1);
}
.previous-day-results-products .products * {
    width: 100%;
    margin: 0;
    padding: 0;
}
.previous-day-results-products .products p {
    font-size: 1.4vw;
    font-weight: 100;
    margin: 0 0 .5vh 0;
}
.previous-day-results-products .products h6 {
    font-size: 1.2vw;
    font-weight: 100;
}
.previous-day-results-products .products h5 {
    font-size: .8vw;
    color: gray;
    width: 100%;
    margin: 0;
    padding: .8vh 0;
    height: fit-content;
}

@media only screen and (max-width: 767px) {
    .previous-day-results {
        margin: 4vh 0;
        overflow: hidden;
    }

    .previous-day-results .inputs h1 {
        font-size: 5vw;
        margin-top: 5vh;
    }

    .previous-day-results .inputs .input-box label {
        width: 48%;
    }

    .previous-day-results .inputs .input-box label p {
        font-size: 3vw;
    }

    .previous-day-results-products {
        overflow-x: scroll;
        overflow-y: hidden; 
        width: auto;
        height: 20vh;
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 5%;
    }

    .previous-day-results-products .products {
        width: 400px;
        /* margin: 0 1vw; */
        max-width: 40%;
        padding: 10px;
        height: 100%;
    }

    .previous-day-results-products .products p {
        font-size: 4vw;
        width: 100%;
        padding: 0;
        margin: 0;
        height: fit-content;
    }

    .previous-day-results-products .products h6 {
        font-size: 5vw;
        width: 100%;
        height: fit-content;
        padding: 0;
        margin: 0;
    }

    .previous-day-results-products .products h5 {
        font-size: 3vw;
        color: gray;
        width: 100%;
        margin: 0;
        padding: 0;
        height: fit-content;
    }

    .previous-day-results .inputs .input-box label select,
    .previous-day-results .inputs .input-box label input {
        width: 100%!important;
        color: #000!important;
    }   
}
