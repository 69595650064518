.fuelStoragePage,
.fuelStorage,
.tankWrap,
.tank {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    box-sizing: border-box;
}
.fuelStoragePage,
.tankWrap {
    width: 100%;
}
.fuelStorage {
    width: 100%;
}
.fuelStoragePage h2 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 2vw;
    color: #252525;
    font-weight: 500;
    margin-bottom: 1vh;
}
.tankWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    column-gap: 2vw;
    row-gap: 2vw;
    margin: 2vh 0;
}
.fuel-storage-query select {
    width: auto;
    padding: 10px;
    font-size: 1vw;
    border: 1px solid lightgray;
    font-family: Montserrat;
}
.tank {
    width: 30%;
    min-height: fit-content;
    background-color: #f6f6f6;
    padding: 1vw;
    border-radius: 10px;
}
.tank .capacity {
    height: 15vh;
    width: 100%;
    border: 1px solid #d3d3d3;
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}
.tank .capacity .level {
    width: 100%;
    background-color: rgba(105, 134, 215, 0.863);
}
@media only screen and (max-width: 767px) {
    .fuel-storage-query select {
        font-size: 5vw;
    }
    .tank {
        width: 48%;
        padding: 5vw;
    }
    .tank h2 {
        font-size: 5vw;
    }
}