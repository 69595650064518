.lastSale {
    width: 100%;
}
.lastSale .tableWrapper {
    display: block;
    overflow: hidden;
}
.lastSale table {
    width: 100%;
    box-sizing: border-box;
    display: table;
    border-collapse: collapse;
    font-family: monospace;
    text-align: center;
    max-width: 100%;
}
@media only screen and (max-width: 767px) {
    .lastSale .tableWrapper {
        overflow-x: scroll;
    }
}