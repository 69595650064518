.shopCharts,
.shopCharts .shopLeft, 
.shopCharts .shopRight,
.shopCharts .shopGroups {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
}
.shopCharts {
    width: 100%;
    height: 45vh;
    margin: 1% 0;
    align-content: flex-start;
}
.shopCharts .shopLeft, 
.shopCharts .shopRight {
    height: 100%;
    padding: 2%;
}
.shopCharts .shopLeft {
    width: 25%;
    background-color: #fff;
    border-radius: 10px;
}
.shopCharts .shopRight {
    width: 74%;
    background-color: #fff;
    border-radius: 10px;
}
.shopGroups {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    box-sizing: border-box;
    position: relative;
    width: 300%;
    height: 30vh;
    margin: .5vh 0;
    background-color: #fff;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-direction: column;
    position: relative;
    padding: 1%;
    border-radius: 10px;
    gap: 2%;
}
.shopGroups a {
    text-decoration: none;
    height: auto;
    width: 17%;
    padding: 1%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-content: flex-start;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    transition: .3s ease-in-out;
    cursor: pointer;
}
.shopGroups a:hover {
    box-shadow: 2px 2px 12px 0px rgb(190, 190, 190);
}
.shopGroups h5 {
    text-decoration: none;
    font-size: 1.2vw;
    font-weight: 700;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 5% 0;
    color: #000;
}
.shopGroups h6 {
    text-decoration: none;
    font-size: 1vw;
    font-weight: 700;
    width: 100%;
    box-sizing: border-box;
    margin: .5vh 0;
    color: #000;
}
.shopGroups h6 span {
    text-decoration: none;
    font-weight: 400;
    color: rgb(173, 173, 173);
}
.shopGroups p {
    width: auto;
    box-sizing: border-box;
    font-size: 1vw;
    font-weight: 800;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid #000;
    border-radius: 0;
    margin: 1vh 0;
}
@media only screen and (max-width: 767px) {
    .shopCharts {
        width: 100%;
        height: 70vh;
        margin: 3% 0;
        align-content: flex-start;
        align-content: space-between;
    }
    .shopCharts .shopLeft, 
    .shopCharts .shopRight {
        width: 100%;
        padding: 2%;
    }
    .shopCharts .shopLeft {
        height: 35%;
    }
    .shopCharts .shopRight {
        height: 63%;
    }
    .shopGroups {
        height: 25vh;
        margin: 0;
    }
    .shopGroups {
        width: 300%;
        padding: 3%;
        border-radius: 10px;
        gap: 2%;
    }
    .shopGroups a {
        height: auto;
        width: 40%;
        padding: 2%;
    }
    .shopGroups a h5 {
        font-size: 3.4vw;
        margin: 0 0 5% 0;
    }
    .shopGroups a h6 {
        font-size: 3vw;
        font-weight: 700;
        width: 100%;
        box-sizing: border-box;
        margin: .5vh 0;
        color: #000;
    }
    .shopGroups a h6 span {
        font-weight: 400;
        color: rgb(173, 173, 173);
    }
    .shopGroups p {
        width: auto;
        box-sizing: border-box;
        font-size: 3.4vw;
        font-weight: 800;
        text-decoration: none;
        color: #000;
        border-bottom: 1px solid #000;
        border-radius: 0;
        margin: 1vh 0;
    }
}