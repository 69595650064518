.yesterdayContainer .title,
.yesterdayContainer .title .dates,
.yesterdayContainer .cumulatives,
.yesterdayContainer .yestTable {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: auto;
}
.yesterdayContainer .title h1 {
    width: 25%;
    margin: 0;
    padding: 0;
    font-size: 1.5vw;
    box-sizing: border-box;
}
.yesterdayContainer .title .dates {
    height: 100%;
    width: 70%;
}
.yesterdayContainer .title .dates label p {
    margin: 0;
    padding: 0;
    font-size: .8vw;
    color: gray;
}
.yesterdayContainer .title .dates select,
.yesterdayContainer .title .dates option,
.yesterdayContainer .title .dates input {
    background-color: #ffffff70;
    font-size: 1vw;
    color: #000;
    font-family: Montserrat;
    border: 0;
    padding: .5vw 1vw;
    margin: 0;
    width: 10vw;
    font-weight: 500;
    box-sizing: border-box;
    border-radius: 0;
}
.yesterdayContainer .cumulatives {
    width: 100%;
    height: 22vh;
    margin: 2vh 0;
    justify-content: flex-start;
    gap: 2%;
}
#Div4243 {
    order: 0
}
#Div4241 {
    order: 1
}
#Div4244 {
    order: 2
}
#Div4245 {
    order: 3
}
#DivP {
    order: 4
}
.yesterdayContainer .yestTable {
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .yesterdayContainer .title .dates label {
        width: 47vw;
    }
    .yesterdayContainer .title .dates label p {
        margin: 1vh 0;
        font-size: 3vw;
    }
    .yesterdayContainer .title .dates select,
    .yesterdayContainer .title .dates option,
    .yesterdayContainer .title .dates input {
        font-size: 4vw;
        padding: 1vw 1vw;
        width: 100%;
        border-radius: 0;
    }
    .yesterdayContainer .cumulatives {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        box-sizing: border-box;
        position: relative;
        width: auto;
        height: 25vh;
        margin: 2vh 0;
        overflow-x: scroll;
        overflow-y: hidden;
        flex-direction: column;
    }
}