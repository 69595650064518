.yesterdayContainer .cumulatives div {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
}
.yesterdayContainer .cumulatives .fuel {
    height: 100%;
    width: 15%;
    border-radius: 10px;
    transition: .3s ease-in-out;
    padding: 1vw;
    background: #ffffff70;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);
}
.yesterdayContainer .cumulatives .fuel:hover {
    box-shadow: 2px 2px 12px 0px rgb(190, 190, 190);
}
.yesterdayContainer .cumulatives .fuel * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
}
.yesterdayContainer .cumulatives .fuel p {
    font-size: .8vw;
    color: gray;
    font-weight: 400;
    margin-top: 1vh;
}
.yesterdayContainer .cumulatives .fuel h6 {
    font-size: 1vw;
    color: #000;
    font-weight: 600;
    width: 100%;
}
.yesterdayContainer .cumulatives .fuel h2 {
    font-size: 1.4vw;
    color: #000;
    font-weight: 600;
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .yesterdayContainer .cumulatives .fuel {
        height: auto;
        width: 40%;
        border-radius: 10px;
        padding: 3vw;
        margin: 0 1.5vw;
    }
    .yesterdayContainer .cumulatives .fuel p {
        font-size: 3vw;
        margin-top: 1vh;
    }
    .yesterdayContainer .cumulatives .fuel h6 {
        font-size: 3.5vw;
        color: #000;
        width: 100%;
    }
    .yesterdayContainer .cumulatives .fuel h2 {
        font-size: 5vw;
        color: #000;
        font-weight: 600;
        width: 100%;
    }
}