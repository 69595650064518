.raport,
.raport .raportSummary,
.raport .raportSummary .circleChart, 
.raport .raportSummary .summary,
.raport .raportSummary .summary div,
.raport .raportDetails,
.raport .raportTimeLine {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
}
.raport {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 1vw;
    border-radius: 5px;
}
.raport h1 {
    width: 100%;
    text-align: center;
    font-size: 2vw;
}
.raport .raportSummary {
    width: 100%;
    height: 30vh;
}
.raport .raportSummary .circleChart {
    height: 100%;
    width: 29%;
}
.raport .raportSummary .circleChart canvas {
    display: block;
    width: 100%;
    height: auto;
}
.raport .raportSummary .summary {
    height: 100%;
    width: 70%;
}
.raport .raportSummary .summary div {
    width: 33.33%;
    height: 50%;
    padding: 5%;
}
.raport .raportSummary .summary .keySummaries {
    width: 50%;
}
.raport .raportSummary .summary div p {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1vw;
    color: gray;
}
.raport .raportSummary .summary div h2 {
    width: 100%;
    margin: 1vh 0;
    padding: 0;
    font-size: 2vw;
    color: #000;
}
.raport .raportDetails {
    width: 100%;
    margin: 2vh 0;
}
.raport .raportDetails div {
    width: auto;
}
.raport .raportDetails div h6 {
    font-size: .6vw;
    color: rgb(167, 165, 165);
}
.raport .raportDetails div h6 span{
    color: lightgray;
}
.raport .raportTimeLine {
    width: 100%;
    height: 40vh;
    margin: 3vh 0;
}
@media only screen and (max-width: 767px) {
    .raport h1 {
        font-size: 5vw;
        margin: 1vh 0 2vh 0
    }
    .raport .raportSummary {
        width: 100%;
        height: 50vh;
    }
    .raport .raportSummary .circleChart {
        height: 100%;
        width: 50%;
    }
    .raport .raportSummary .circleChart canvas {
        display: block;
        width: 100%;
        height: auto;
    }
    .raport .raportSummary .summary {
        height: 100%;
        width: 50%;
    }
    .raport .raportSummary .summary div {
        width: 100%;
        height: 20%;
        padding: 5%;
    }
    .raport .raportSummary .summary .keySummaries {
        width: 100%;
    }
    .raport .raportSummary .summary div p {
        font-size: 3vw;
    }
    .raport .raportSummary .summary div h2 {
        font-size: 5vw;
    }
    .raport .raportDetails {
        width: 100%;
        margin-bottom: 2vh;
    }
    .raport .raportDetails div {
        width: 49%;
    }
    .raport .raportDetails div h6 {
        font-size: 2vw;
        margin: 0;
        padding: 1vh 0;
    }
    .raport .raportTimeLine {
        width: 100%;
        height: 40vh;
        margin: 3vh 0;
    }
}