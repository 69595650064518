.pricesOverview,
.pricesTitle,
.pricesContainer,
.pricesContainer .fuelPrice {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
}
.pricesOverview {
    width: 100%;
}
.pricesTitle {
    width: 100%;
    height: auto;
    border-bottom: 1px solid rgb(179, 179, 179);
    padding: 2vh 0;
}
.pricesTitle h1 {
    margin: 0;
    font-size: 2vw;
    color: rgb(19, 19, 19);
}
.pricesTitle select {
    font-family: Montserrat;
    padding: 5px 10px;
    border: 0;
    background-color: #ffffff8a;
    border-radius: 0px;
    height: auto;
    width: 30%;
    font-size: 1vw;
    font-weight: 400;
    box-sizing: border-box;
    color: #000000;
}
.pricesContainer {
    width: 100%;
    height: auto;
    padding: 2%;
}
.pricesContainer .fuelPrice {
    width: 20%;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    transition: .3s ease-in-out;
    padding: 1vw;
    background: #ffffff70;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);
}
.pricesContainer .fuelPrice:hover {
    box-shadow: 2px 2px 12px 0px rgb(190, 190, 190);
}
.pricesContainer .fuelPrice h6 {
    font-size: 1.4vw;
    font-weight: 600;
    box-sizing: border-box;
    margin: 0;
}
.pricesContainer .fuelPrice h2 {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 1vh 0;
    font-size: 3vw;
    font-weight: 600;
}
.pricesContainer .fuelPrice p {
    box-sizing: border-box;
    text-align: left;
    margin: 1vh 0;
    font-size: .8vw;
    font-weight: 300;
    color: rgb(177, 177, 177);
    transition: .3s;
}
.pricesContainer .fuelPrice p:hover {
    color: #000;
}
@media only screen and (max-width: 767px) {
    .pricesTitle h1 {
        font-size: 5vw;
    }
    .pricesTitle select {
        padding: 5px;
        width: 60%;
        font-size: 4vw;
        font-weight: 400;
    }
    .pricesContainer {
        padding: 0;
        height: fit-content;
    }
    .pricesContainer .fuelPrice {
        width: 48%;
        padding: 3%;
        margin-top: 2vh;
    }
    .pricesContainer .fuelPrice h6 {
        font-size: 4.2vw;
    }
    .pricesContainer .fuelPrice h2 {
        font-size: 8vw;
        margin: 2vh 0;
    }
    .pricesContainer .fuelPrice p {
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        margin: 1vh 0;
        font-size: 3vw;
    }
}