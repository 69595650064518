.homepage,
.homepage .dailyContainer,
.homepage .yesterdayContainer,
.homepage .dailyContainer .bottomTab {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    flex-direction: row;
}
.homepage {
    width: 100%;
    min-height: 100vh;
    align-content: flex-start;
}
.homepage .dailyContainer {
    width: 100%;
    height: 60vh;
    justify-content: space-between;
    align-content: space-between;
    align-items: stretch;
}
.homepage .leftCol, 
.homepage .rightCol {
    position: relative;
    box-sizing: border-box;
}
.homepage .leftCol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    box-sizing: border-box;
    width: 22.5%;
    height: 100%;
}
.homepage .rightCol {
    width: 77%;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
}
.homepage .leftCol div, 
.homepage .rightCol div {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-radius: 10px;
}
.homepage .rightCol div {
    height: 100%;
    align-content: space-between;
}
.homepage .leftCol div {
    height: 32%;
    width: 100%;
    border-radius: 10px;
    transition: .3s ease-in-out;
    background: #ffffff70;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);
}
.homepage .leftCol div:hover {
    box-shadow: 2px 2px 12px 0px rgb(190, 190, 190);
}
.homepage .dailyContainer p {
    width: 100%;
    font-size: 1vw;
    color: gray;
    margin: 0;
    padding: 0;
}
.homepage .dailyContainer h2 {
    width: 100%;
    font-size: 2.2vw;
    color: #000;
    font-weight: 800;
    margin: 0;
    padding: 1vh 0;
}
.homepage .dailyContainer h2 span {
    font-weight: 300;
    font-size: 1.6vw;
    color: rgb(162, 162, 162);
}
.homepage .dailyContainer h6 {
    width: 100%;
    font-size: .6vw;
    font-weight: 300;
    color: rgb(177, 177, 177);
    margin: 0;
    padding: 0;
}
.homepage .dailyContainer h6 span {
    font-size: 1vw;
    font-weight: 400;
}
.homepage .yesterdayContainer {
    width: 100%;
    justify-content: center;
    height: auto;
    align-items: flex-start;
    align-content: flex-start;
    margin: 2vh 0;
}
.homepage .yesterdayContainer hr {
    width: 100%;
    background-color: #cccccc;
    height: .5px;
    border: 0;
}
.homepage .yesterdayContainer h1 {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 2vh;
    font-size: 1.8vw;
    color: #252525;
    font-weight: 600;
}
@media only screen and (max-width: 767px) {
    .mobile-line-break {
        display: block;
    }
    .homepage .dailyContainer {
        width: 100%;
        height: 70vh;
        justify-content: space-between;
        align-content: space-between;
        align-items: stretch;
        flex-direction: column;
    }
    .homepage .dailyContainer .leftCol,
    .homepage .dailyContainer .rightCol {
        width: 100%;
        height: 50%;
    }
    .homepage .dailyContainer p {
        width: 100%;
        font-size: 3vw;
        color: rgba(149, 149, 149, 0.964);
        margin: 0;
        padding: 0;
    }
    .homepage .dailyContainer h2 {
        width: 100%;
        font-size: 4.4vw;
        color: #000;
        font-weight: 700;
        margin: 0;
        padding: 1.4vh 0;
    }
    .homepage .dailyContainer h2 span {
        font-size: 2.8vw;
    }
    .homepage .dailyContainer h6 {
        width: 100%;
        font-size: 1.8vw;
        font-weight: 300;
        color: rgb(206, 206, 206);
        margin: 0;
        padding: 0;
    }
    .homepage .dailyContainer h6 span {
        font-size: 3vw;
        font-weight: 500;
        width: 100%;
    }
    .homepage .dailyContainer .leftCol {
        height: 30%;
        padding-bottom: 1vh;
    }
    .homepage .leftCol div {
        height: 100%;
        width: 32%;
    }
    .homepage .yesterdayContainer {
        margin: 5vh 0;
    }
    .homepage .dailyContainer .rightCol {
        height: 70%;
    }
    .homepage .yesterdayContainer .title h1 {
        width: 100%;
        font-size: 5vw;
    }
    .homepage .yesterdayContainer .title .dates {
        width: 100%;
    }
    .homepage .rightCol div {
        height: 110%;
    }
}