.shopDashboard,
.shopNumbers,
.shopNumbers .shopNumber,
.shopDashboard .labels,
.shopDashboard .labels label {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
}
.shopDashboard {
    width: 100%;
    min-height: 100vh;
    align-content: flex-start;
}
.shopDashboard
.shopNumbers {
    height: 20vh;
    flex-direction: row;
}
.shopDashboard h1 {
    width: 100%;
    margin: 1vh 0 2vh 0;
    display: inline-block;
    font-size: 2.2vw;
    font-weight: 600;
}
.shopDashboard h1 span {
    width: auto;
    color: #919191;
    font-size: 1.8vw;
    font-weight: 300!important;
}
.shopNumbers .shopNumber {
    width: 32.5%;
    height: 100%;
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
}
.shopNumbers .shopNumber p {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 1vw;
    color: rgb(143, 143, 143);
    font-weight: 300;
}
.shopNumbers .shopNumber h4 {
    width: 100%;
    margin: 0;
    padding: 1vh 0;
    font-size: 2.6vw;
    color: #000;
}
.shopNumbers .shopNumber h6 {
    width: 100%;
    font-size: 1vw;
    margin: .2vh 0;
    color: #000;
}
.shopNumbers .shopNumber h6 span {
    width: 100%;
    font-size: .8vw;
    margin: 0;
    font-weight: 300;
    color: #919191;
}
.shopDashboard p {
    font-size: 2vw;
    margin: 0;
    padding: 0;
    width: auto;
    font-weight: 300;
}
.shopDashboard p b {
    font-weight: 800;
}
.shopDashboard .labels {
    width: 45%;
    margin: 2vh 0;
    gap: 5%;
}
.shopDashboard .labels label p {
    font-size: 1vw;
    color: gray;
    margin-right: .8vw;
}
.shopDashboard .labels label input {
    font-size: 1vw;
    font-family: Montserrat;
    background-color: #fff;
    border: 0px;
    padding: 5px 10px;
    color: #000;
}
@media only screen and (max-width: 767px) {
    .shopNumbers {
        height: 50vh;
        flex-direction: column;
    }
    .shopDashboard h1 {
        font-size: 5vw;
    }
    .shopDashboard h1 span {
        width: 100%;
        font-size: 3vw;
    }
    .shopDashboard .shopNumbers {
        height: 50vh;
        align-content: space-between;
    }
    .shopNumbers .shopNumber {
        width: 100%;
        height: 32%;
        padding: 5%;
        border-radius: 10px;
    }
    .shopNumbers .shopNumber p {
        font-size: 3.8vw;
        color: rgb(157, 157, 157);
    }
    .shopNumbers .shopNumber h4 {
        font-size: 6vw;
    }
    .shopNumbers .shopNumber h6 {
        font-size: 3.4vw;
    }
    .shopNumbers .shopNumber h6 span {
        font-size: 3vw;
    }
    .shopDashboard p {
        font-size: 6vw;
        width: auto;
    }
    .shopDashboard .labels {
        width: 100%;
    }
    .shopDashboard .labels label {
        box-sizing: border-box;
        width: 45%;
    }
    .shopDashboard .labels label p {
        font-size: 3vw;
    }
    .shopDashboard .labels label input {
        font-size: 3.6vw;
        border-radius: 0px;
    }
}