.shopGroupDetails,
.shopGroupDetails .header,
.shopGroupDetails .header .summaries,
.shopGroupDetails .header .summaries div,
.detailTable {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: auto;
}
.shopGroupDetails,
.shopGroupDetails .header .summaries {
    width: 100%;
}
.shopGroupDetails .header {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}
.shopGroupDetails .header button {
    width: 3vw;
    height: 3vw;
    display: block;
    padding: 5px;
    background-color: #f5f5f5;
    border-radius: 100%;
    font-family: Montserrat;
    border: 0;
    color: #252525;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.shopGroupDetails .header button svg {
    fill: #252525;
    width: 1.5vw;
    height: 1.5vw;
    transition: .3s ease-in-out;
}
.shopGroupDetails .header button:hover {
    background-color: #252525;
}
.shopGroupDetails .header button:hover svg {
    fill: #fff;
}
.shopGroupDetails .header h2 {
    width: 90%;
    margin: 0;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 600;
    box-sizing: border-box;
}
.shopGroupDetails .header .summaries {
margin: 2vh 0;
}

.shopGroupDetails .header .summaries div {
    background-color: #fff;
    width: 18%;
    height: 100%;
    padding: .8vw;
    border-radius: 5px;
}
.shopGroupDetails .header .summaries div h6 {
    width: 100%;
    margin: 0;
    font-size: 1.4vw;
    color: #252525;
    font-weight: 700;
}
.shopGroupDetails .header .summaries div h5 {
    width: 100%;
    margin: 0;
    font-size: .8vw;
    color: rgb(152, 152, 152);
    font-weight: 300;
}

/* Table */
.detailTable {
    width: 100%;
}
.detailTable table {
    table-layout: fixed;
    border-collapse: collapse;
    box-sizing: border-box;
    width: 100%;
}
.detailTable table thead {
    background-color: rgb(215, 215, 215);
    font-size: .8vw;
}
.detailTable table thead tr th {
    padding: 2vh 0;
    color: #616161;
}
.detailTable table tbody tr {
    border-bottom: 1px dashed #d1d1d1;
    transition: .1s ease-in-out;
}
.detailTable table tbody tr:hover {
    background-color: #e2e2e2;
}
.detailTable table tbody tr td p {
    font-size: .8vw;
    text-align: center;
    margin: 1vh 0;
}

@media only screen and (max-width: 767px) {
    .shopGroupDetails .header button {
        width: 10vw;
        height: 10vw;
        padding: 5px;
    }
    .shopGroupDetails .header button svg {
        width: 5vw;
        height: 5vw;
    }
    .shopGroupDetails .header h2 {
        width: 85%;
        margin: 0;
        font-size: 6vw;
    }
    .shopGroupDetails .header .summaries {
        margin: 2vh 0;
    }
    .shopGroupDetails .header .summaries div {
        width: 49%;
        padding: 2vw;
        margin: .5vh 0;
    }
    .shopGroupDetails .header .summaries div h6 {
        font-size: 6vw;
    }
    .shopGroupDetails .header .summaries div h5 {
        font-size: 3vw;
    }
    .detailTable {
        overflow-x: scroll;
        overflow-y: hidden;
        width: 280%;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        align-content: flex-start;
    }
    .detailTable table {
        width: 280%;
        position: relative;
    }
    .detailTable table thead {
        font-size: 3vw;
    }
    .detailTable table tbody tr td p {
        font-size: 3vw;
    }
}