.station-page {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-wrap: wrap;
}
.station-page .left-column,
.station-page .data {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.station-page .upper-data {
    width: 100%;
    height: 20%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: flex-start;
}
.station-page .data {
    width: 100%;
    height: 80%;
    flex-wrap: wrap;
    display: flex;
}
.station-page .left-column {
    width: 20%;
}
.station-page .data .chart {
    width: 80%;
    height: 100%;
}
.station-page .single-result {
    width: 100%;
    height: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 10px;
}
.station-page .single-result * {
    width: 100%;
    margin: 0;
    padding: 0;
}
.station-page .single-result h2 {
    margin: 10px 0;
}

.station-page .upper-data .single-product {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}
.station-page .upper-data .single-product * {
    width: 100%;
    margin: 0;
    padding: 0;
}
.station-page .upper-data .single-product h6 {
    font-size: 1vw;
}
.station-page .upper-data .single-product h3 {
    font-size: 1.6vw;
}
.station-page .upper-data .single-product p {
    font-size: .8vw;
}
.station-page .upper-data .single-product p span {
    color: #6d6d6d;
}
.station-page .chart {
    width: 100%;
    height: 80%;
}

@media only screen and (max-width: 767px) {
    .station-page {
        width: 100%;
        height: 80vh;
    }
    .station-page .upper-data {
        width: 100%;
        height: 30%;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .station-page .data {
        width: 100%;
        height: 75%;
        flex-direction: column-reverse;
        margin: 1vh 0;
    }
    .station-page .left-column {
        width: 100%;
        height: 30%;
        justify-content: space-between;
        padding: 1vh 0;
    }
    .station-page .data .chart {
        width: 100%;
        height: 70%;
    }
    .station-page .single-result {
        width: 30%;
        height: 100%;
        justify-content: flex-start;
        padding: 10px;
        background-color: #fff;
    }
    .station-page .single-result p {
        font-size: 3.2vw;
        text-align: center;
    }
    .station-page .single-result h2 {
        font-size: 4.2vw;
        text-align: center;
    }
    .station-page .single-result h6 {
        font-size: 2.6vw;
        text-align: center;
    }
    .station-page .upper-data .single-product {
        width: 33.3333%;
        height: 50%;
    }
    .station-page .upper-data .single-product * {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .station-page .upper-data .single-product h6 {
        font-size: 4vw;
    }
    .station-page .upper-data .single-product h3 {
        font-size: 3.8vw;
    }
    .station-page .upper-data .single-product p {
        font-size: 2.4vw;
    }
    .station-page .chart {
        width: 100%;
        height: 60%;
    }
}