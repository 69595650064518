/* .yestTable {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    position: relative;
}
.yestTable table {
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;
}
.yestTable table thead tr {
    background-color: #d9d9d9;
    border: 3px solid #d9d9d9;
}
.yestTable table thead tr th {
    font-size: 1.2vw;
    font-weight: 400;
    color: #ababab;
    padding: 5px 10px;
}
.yestTable table tbody tr {
    background-color: transparent;
    box-sizing: border-box;
    transition: .1s ease-in-out;
}
.yestTable table tbody tr:hover {
    background-color: #c9c9c9;
}
.yestTable table tbody tr td {
    font-size: 1.2vw;
    color: #000;
    font-weight: 500;
    border-bottom: .8px dashed #979797;
    padding: 5px 10px;
    border-radius: 0;
    box-sizing: border-box;
}
.yestTable table tbody tr td:nth-child(2) {
    text-align: center;
}
.yestTable table tbody tr td:nth-child(3), 
.yestTable table tbody tr td:nth-child(4) {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .yestTable table thead tr {
        background-color: #333333;
        border: 3px solid #333333;
    }
    .yestTable table thead tr th {
        font-size: 3.5vw;
    }
    .yestTable table tbody tr td {
        font-size: 3.4vw;
        border-bottom: 2px dashed #979797;
    }
} */
.yesterdayContainer .yestTable table {
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;
    position: relative;
    display: table;
}
.yesterdayContainer .yestTable table thead tr {
    background-color: #d7d7d7;
    border: 3px solid #d7d7d7;
}
.yesterdayContainer .yestTable table thead tr th p {
    padding: 1vh 0;
    margin: 0;
    color: #9e9e9e;
    font-size: 1vw;
    font-weight: 300;
}
.yesterdayContainer .yestTable table tbody tr:not(:last-child) {
    border-bottom: .5px solid rgb(209, 209, 209);
}
.yesterdayContainer .yestTable table tbody tr td p {
    margin: 0;
    padding: .3vh 0;
    color: #363636;
    font-size: 1vw;
}
.yesterdayContainer .yestTable table tbody tr {
    transition: .1s ease-in-out;
}
.yesterdayContainer .yestTable table tbody tr:hover {
    background-color: rgb(209, 209, 209);
}
@media only screen and (max-width: 767px) {
    .yesterdayContainer .yestTable table thead tr th p {
        padding: 1vh 1vw;
        font-size: 3.2vw;
    }
    .yesterdayContainer .yestTable table tbody tr td p {
        padding: .3vh 0;
        font-size: 4vw;
    }
}