.load {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #d1d1d1, #ffffff00);
	background-size: 400% 400%;
	animation: gradient 4s ease infinite;
  padding: 5%;
  border-radius: 5px;
}
.load p {
  font-size: 18px;
  color: rgb(174, 174, 174);
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  display: block;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}