.pricesChart {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 50vh;
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
    margin: 0;
}
.pricesChart canvas {
    display: block;
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .pricesChart {
        margin: 2vh 0;
    }
}