.fuelDelivery,
.fuelDelivery .search,
.fuelDeliResults,
.fuelDeliResults div {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}
.fuelDelivery h1 {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 2vw;
    font-weight: 600;
    box-sizing: border-box;
    margin-bottom: 2vh;
}
.fuelDelivery .search {
    width: 100%;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 10px;
    padding: 1vw;
}
.fuelDelivery .search label {
    width: 18%;
    box-sizing: border-box;
    display: block;
}
.fuelDelivery .search label p {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1vw;
    color: #a09f9f;
    font-weight: 300;
}
.fuelDelivery .search label select,
.fuelDelivery .search label input,
.fuelDelivery .search label option {
    border: 0;
    background-color: rgb(235, 235, 235);
    padding: 5px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-family: Montserrat;
    font-size: 1vw;
    font-weight: 500;
    border-radius: 0;
}
.fuelDeliResults {
    box-sizing: border-box;
    width: 100%;
}
.fuelDeliResults div {
    width: 100%;
    padding: 2%;
    background-color: #fff;
    border-radius: 10px;
}
.fuelDeliResults h6 {
    width: 100%;
    margin: 2vh 0;
    font-weight: 300;
    color: rgb(145, 145, 145);
    display: block;
    font-size: 1.2vw;
}
.fuelDeliResults table {
    width: 100%;
    padding: 2%;
    box-sizing: border-box;
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
}
.fuelDeliResults table thead th {
    background-color: #e2e2e2;
    font-size: 1vw;
    font-weight: 500;
    color: rgb(165, 165, 165);
    padding: 10px 0;
}
.fuelDeliResults table tbody td p {
    margin: 0;
    padding: 5px 10px;
    box-sizing: border-box;
    border-bottom: 1px dashed lightgray;
    font-size: 1.2vw;
    border-radius: 0px;
    color: #252525;
}
@media only screen and (max-width: 767px) {
    .fuelDelivery h1 {
        font-size: 6vw;
    }
    .fuelDelivery .search {
        width: 100%;
        padding: 2%;
        justify-content: space-between;
    }
    .fuelDelivery .search label {
        width: 48%;
    }
    .fuelDelivery .search label p {
        font-size: 2.8vw;
    }
    .fuelDelivery .search label select,
    .fuelDelivery .search label input,
    .fuelDelivery .search label option {
        font-size: 3.6vw;
    }
    .fuelDeliResults .fuelTable {
        width: 200vw;
        overflow-x: scroll;
        display: block;
    }
    .fuelDeliResults .fuelTable table {
        width: 150%;
    }
    .fuelDeliResults h6 {
        font-size: 3.4vw;
    }
    .fuelDeliResults table thead th {
        font-size: 3.2vw;
    }
    .fuelDeliResults table tbody td p {
        font-size: 3.2vw;
        padding: 5px;
        border-bottom: 2px dashed lightgray;
    }
}