header,
.privateBody,
.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    box-sizing: border-box;
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    position: relative;
}
.mobileMenu, header .mobileBttn {
    display: none;
}
.privateBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    position: relative;
}
header {
    width: 15vw;
    max-width: 15%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 1%;
    background-color: #252525;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}
header .station select {
    font-family: Montserrat;
    padding: .5vw;
    border: 0;
    background-color: #b3b3b38a;
    border-radius: 0px;
    height: auto;
    width: 100%;
    font-size: .8vw;
    font-weight: 400;
    box-sizing: border-box;
    color: #d8d8d8;
}
.content {
    display: block;
    width: 85vw;
    max-width: 85%;
    min-height: 100vh;
    height: fit-content;
    position: relative;
    background-color: #ebebeb;
    padding: 1%;
    font-family: Montserrat;
}
header img {
    height: auto;
    width: 100%;
    box-sizing: border-box;    
}
header ul {
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}
header ul li {
    width: 100%;
    box-sizing: border-box;
    display: block;
}
header ul li:not(:last-child) {
    margin-bottom: 1.5vh;
}
header ul li a {
    text-decoration: none;
    font-family: Montserrat;
    color: #c5c4c4;
    font-size: 1vw;
    font-weight: 300;
    cursor: pointer;
    width: 100%;
    display: block;
    padding: 5px;
    border-radius: 10px;
}
header ul li a span {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    margin-right: 10px;
}
header ul li a span svg {
    width: 25px;
    height: 25px;
    stroke-width: .8;
    stroke: #c5c4c4;
    fill: none;
}
header .desktopMenu {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 50%;
}
header ul a:hover {
    background-color: #c5c4c423;
}
header button {
    width: 100%;
    padding: .5vh 0;
    border-radius: 0px;
    font-family: Montserrat;
    font-size: 1vw;
    font-weight: 400;
    border: 0px;
    background-color: #464646;
    cursor: pointer;
    color: #c9c9c9;
    transition: .3s ease-in-out;
}
header button span {
    vertical-align: middle;
    margin-left: 5px;
}
header .station {
    display: block;
    width: 100%;
}
@media only screen and (max-width: 767px) {
    header button,
    header .desktopMenu {
        display: none;
    }
    .privateBody {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        max-width: 100%;
        height: 100vh;
        position: relative;
        background-color: #fff;
    }
    .content {
        width: 100vw;
        max-width: 100%;
        height: fit-content;
        padding: 2%;
    }
    header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100vw;
        max-width: 100%;
        height: 10vh;
        min-height: 10vh;
        max-height: 10%;
        background-color: #252525;
        padding: 4%;
        position: relative;
        overflow: hidden;
        border-radius: 0;
    }
    header .mobileBttn {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 10%;
        height: 60%;
        box-sizing: border-box;
    }
    header .mobileBttn span{
        display: block;
        position: relative;
        width: 100%;
        height: 2px;
        background-color: #fff;
        box-sizing: border-box;
        transition: .3s;
    }
    header a {
        height: 100%;
        width: auto;
    }
    header img {
        height: 100%;
        width: auto;
    }
    header .mobileMenu {
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        justify-content: center;
        top: 10vh;
        left: 0vw;
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        min-width: 100%;
        background-color: #252525;
        padding: 0;
        transition: .3s;
        z-index: 3;
    }
    header ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 20px;
        padding: 5%;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        margin-top: 5%;
    }
    header ul a {
        display: block;
        font-size: 34px;
        width: 100%;
        box-sizing: border-box;
        height: 15%;
    }
    header .mobileMenu button {
        width: 80%;
        display: block;
        border-radius: 0px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 300;
        padding: 5px 20px;
        background-color: transparent;
        border: 1px solid #c0c0c0;
        color: #c0c0c0;
        height: 5%;
    }
    header .station {
        display: none;
    }
    header ul li:not(:last-child) {
        margin-bottom: 2vh;
    }
    header ul li a {
        font-size: 6vw;
    }
    header ul li a span {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        margin-right: 10px;
    }
    header ul li a span svg {
        width: 30px;
        height: 30px;
        stroke-width: .8;
        stroke: #c5c4c4;
        fill: none;
    }
    header ul a:hover {
        background-color: transparent;
    }
}