.loginpage,
.loginpage form,
.loginpage input, 
.loginpage button {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: Montserrat;
}
.loginpage {
    background: linear-gradient(-45deg, #0d0646, #231e4e, #b85224, #ff3c00);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
.loginpage form {
    background-color: #fff;
    width: 30%;
    height: 50%;
    border-radius: 10px;
    box-shadow: 2px 2px #0000001e;
    flex-direction: column;
    padding: 1%;
    flex-wrap: nowrap;
}
.loginpage img {
    width: auto;
    height: 25%;
    box-sizing: border-box;
}
.loginpage p {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 400;
    margin: 1vh 0;
}
.loginpage input {
    width: 70%;
    box-sizing: border-box;
    margin: .5vh 0;
    border: 1px solid rgb(163, 163, 163);
    border-radius: 0;
    color: #000;
    padding: 5px;
    font-size: 1vw;
}
.loginpage button {
    box-sizing: border-box;
    width: 70%;
    margin: 1.5% 0;
    border-radius: 0;
    color: #fff;
    border: 0px;
    padding: 10px;
    background-color: #1d1d1d;
    cursor: pointer;
    transition: .3s;
}
.loginpage button:hover {
    background-color: #ee7943;
    color: #000;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media only screen and (max-width: 767px) {
    .loginpage form {
        width: 90%;
        height: 50%;
    }
    .loginpage img {
        width: 80%;
        height: auto;
    }
    .loginpage p {
        font-size: 30px;
        font-weight: 400;
        margin: 3% 0;
    }
    .loginpage input {
        width: 70%;
        margin: 2% 0;
        height: 40px;
        font-size: 18px;
    }
    .loginpage button {
        width: 70%;
        margin: 3% 0;
        padding: 20px;
        font-size: 20px;
    }
}